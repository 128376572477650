import React from 'react';
import Logo from 'components/Logo/Logo';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import Transition from 'components/Transition/Transition';

export default function Nav() {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(
    false,
  );
  const [
    // eslint-disable-next-line no-unused-vars
    hideSidebarMenu,
    setHideSidebarMenu,
  ] = React.useState(true);

  const openSidebar = () => {
    setIsSidebarOpen(true);
    setHideSidebarMenu(false);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const hideSidebar = () => {
    setHideSidebarMenu(true);
  };

  const { pathname } = useLocation();

  return (
    <div className="z-10 relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
      <nav className="relative max-w-screen-xl mx-auto flex items-center justify-between px-4 sm:px-6">
        <div className="flex items-center flex-1">
          <div className="flex items-center justify-between w-full md:w-auto">
            <a href="/" aria-label="Home">
              {/* <img
                  className="h-8 w-auto sm:h-10"
                  src="https://tailwindui.com/img/logos/workflow-mark-on-white.svg"
                  alt="Logo"
                /> */}
              <Logo />
            </a>
            <div className="-mr-2 flex items-center md:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                id="main-menu"
                aria-label="Main menu"
                aria-haspopup="true"
                onClick={openSidebar}
              >
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="hidden md:block md:ml-10">
            {pathname === '/projects' ? (
              <a
                href="#top"
                className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
              >
                Projects
              </a>
            ) : (
              <Link
                to="/projects"
                className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
              >
                Projects
              </Link>
            )}
            {/* {pathname === '/publications' ? (
              <a
                href="#top"
                className="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
              >
                Publications
              </a>
            ) : (
              <Link
                to="/publications"
                className="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
              >
                Publications
              </Link>
            )} */}
            <a
              // href="/rui-zhang-curriculum-vitae.pdf"
              href="https://drive.google.com/file/d/100LCURsJEldJ8Mez2KWDtDas6i6TwAeR/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
            >
              Resume
              {/* Curriculum vitae */}
            </a>
            {pathname === '/' ? (
              <a
                href="/contact"
                className="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
              >
                Contact
              </a>
            ) : (
              <Link
                to="/contact"
                className="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
              >
                Contact
              </Link>
            )}
          </div>
        </div>
        {/* <div className="hidden md:block text-right">
            <span className="inline-flex rounded-md shadow-md">
              <span className="inline-flex rounded-md shadow-xs">
                <a
                  href="/"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-green-600 bg-white hover:bg-gray-50 focus:outline-none focus:border-green-300 focus:shadow-outline-green transition duration-150 ease-in-out"
                >
                  Log in
                </a>
              </span>
            </span>
          </div> */}
      </nav>

      {/* <!--
          Mobile menu, show/hide based on menu open state.

          Entering: "duration-150 ease-out"
            From: "opacity-0 scale-95"
            To: "opacity-100 scale-100"
          Leaving: "duration-100 ease-in"
            From: "opacity-100 scale-100"
            To: "opacity-0 scale-95"
        --> */}
      <Transition
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        show={isSidebarOpen}
        onExited={hideSidebar}
      >
        <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-md">
            <div
              className="rounded-lg bg-white shadow-xs overflow-hidden"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="main-menu"
            >
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  {/* <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-on-white.svg"
                    alt=""
                  /> */}
                  <Logo />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    aria-label="Close menu"
                    onClick={closeSidebar}
                  >
                    <svg
                      className="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3">
                {pathname === '/projects' ? (
                  <a
                    href="#top"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    role="menuitem"
                  >
                    Projects
                  </a>
                ) : (
                  <Link
                    to="/"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    role="menuitem"
                  >
                    Projects
                  </Link>
                )}
                {/* {pathname === '/publications' ? (
                  <a
                    href="#top"
                    className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    role="menuitem"
                  >
                    Publications
                  </a>
                ) : (
                  <Link
                    to="/publications"
                    className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    role="menuitem"
                  >
                    Publications
                  </Link>
                )} */}
                <a
                  // href="/rui-zhang-curriculum-vitae.pdf"
                  href="https://drive.google.com/file/d/100LCURsJEldJ8Mez2KWDtDas6i6TwAeR/view?usp=sharing"
                  target="_blank"
                  className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                  rel="noopener noreferrer"
                >
                  Resume
                  {/* Curriculum vitae */}
                </a>
                {pathname === '/contact' ? (
                  <a
                    href="#top"
                    className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    role="menuitem"
                  >
                    Contact
                  </a>
                ) : (
                  <Link
                    to="/contact"
                    className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    role="menuitem"
                  >
                    Contact
                  </Link>
                )}
              </div>
              {/* <div>
                <a
                  href="/"
                  className="block w-full px-5 py-3 text-center font-medium text-green-600 bg-gray-50 hover:bg-gray-100 hover:text-green-700 focus:outline-none focus:bg-gray-100 focus:text-green-700 transition duration-150 ease-in-out"
                  role="menuitem"
                >
                  Log in
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
