import React from 'react';

import Seo from 'components/Seo/Seo';
import Nav from 'components/Nav/Nav';
import Footer from 'components/Footer/Footer';

const NotFoundPage = () => (
  <React.Fragment>
    <Seo title="404: Not found" />
    <div className="min-h-screen flex flex-col">
      <Nav />
      <div className="flex-grow flex flex-col justify-center">
        <div class="py-12 bg-white">
          <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="lg:text-center">
              <p class="text-base leading-6 text-green-600 font-semibold tracking-wide uppercase">
                Uh oh!
              </p>
              <h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                Not found
              </h3>
              <p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
                You just hit a route that doesn&#39;t
                exist... the sadness.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </React.Fragment>
);

export default NotFoundPage;
